.faq{
   width:90vw;
   margin:auto;
  width:75vw;

}

.faq_container{
    margin:60px 0px;
    
}
.faqhead{
    text-align: center;
    font-size: 50px;
    margin-bottom: 20px;
    width:100%;
}