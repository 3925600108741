.text{
    font-size:18px;
}
.smallCardDiv{
    display: flex;
  
    flex-direction: row;

}
.smallCard{
    background-color: white;
    margin: 10px;
   width:250px;
   min-height: 120px;
   border-radius: 10px;
   padding: 12px;
}
b{
    margin-right: 5px;
}